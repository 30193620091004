import React, { useEffect, useState, useCallback } from 'react';
import Chess from './chessjs';

interface Props {
  position: string;
}

enum QuestionType {
  Checks, Captures, Threats, Unprotected
}

function ChessFacts(props: Props) {
  const [turnStr, setTurnStr] = useState('');
  const [questionType, setQuestionType] = useState(QuestionType.Checks);
  const [answers, setAnswers] = useState<number[]>([]);
  const [correctAnswer, setCorrectAnswer] = useState<number>(0);
  const [result, setResult] = useState('');

  useEffect(() => {
    const chess = (Chess as any)() as ChessInstance;

    setResult('');

    chess.load(props.position);
    let turnStr = chess.turn() === 'w' ? 'White' : 'Black';
    setTurnStr(turnStr);
    
    let checks = 0, captures = 0;
    for (const move of chess.moves()) {
      const moveResult = chess.move(move);
      if (!moveResult)
        throw new Error(`Illegal move: ${move}`);
      
      if (moveResult.flags !== chess.FLAGS.EP_CAPTURE && moveResult.flags !== chess.FLAGS.KSIDE_CASTLE && moveResult.flags !== chess.FLAGS.QSIDE_CASTLE) {
        if (moveResult.captured) {
          console.log('capture:', move);
          captures++;
        }

        if (chess.in_check())
          checks++;
      }
      chess.undo();
    }

    let totalOwnSquares = 0;
    for (const square of chess.SQUARES) {
      const info = chess.get(square);
      if (info?.color === chess.turn())
        totalOwnSquares++;
    }
    totalOwnSquares--; // Don't count the king
    let protectedSquares: { [key: string]: true } = {};
    for (const move of chess.moves({ only_protect_moves: true, verbose: true })) {
      if (chess.get(move.to)?.type === chess.KING) // It doesn't make sense to protect your king
        continue;
      protectedSquares[move.to] = true;
    }
    console.log('protected square', Object.keys(protectedSquares));
    const totalProtectedSquares = Object.keys(protectedSquares).length;
    const unprotectedSquares = totalOwnSquares - totalProtectedSquares;

    let threatenedSquares: { [key: string]: true } | null = null;
    if (!chess.in_check()) {
      threatenedSquares = {};
      const oppositeFen = props.position.replace(/ [b|w] /i, turn => / b /i.test(turn) ? ' w ' : ' b ');
      chess.load(oppositeFen);

      for (const move of chess.moves()) {
        const moveResult = chess.move(move);
        if (!moveResult)
          throw new Error(`Illegal move: ${move}`);

        if (moveResult.captured) {
          threatenedSquares[moveResult.to] = true;
        }

        chess.undo();
      }

      console.log('threatened squares:', Object.keys(threatenedSquares));
    }

    const questionPool: QuestionType[] = [];

    questionPool.push(QuestionType.Checks);
    if (checks > 0)
      questionPool.push(QuestionType.Checks);

    questionPool.push(QuestionType.Captures);
    if (captures > 1)
      questionPool.push(QuestionType.Captures);

    let threats = threatenedSquares ? Object.keys(threatenedSquares).length : null;
    if (threats !== null) {
      questionPool.push(QuestionType.Threats);
      if (threats > 1)
        questionPool.push(QuestionType.Threats);
    }

    questionPool.push(QuestionType.Unprotected);
    if (unprotectedSquares > 1)
      questionPool.push(QuestionType.Unprotected);

    let questionType = questionPool[(Math.random() * questionPool.length)|0];
    let answer: number;
    setQuestionType(questionType);
    switch (questionType) {
      case QuestionType.Checks:
        answer = checks;
        break;
      case QuestionType.Captures:
        answer = captures;
        break;
      case QuestionType.Threats:
        if (threats === null)
          throw new Error('Threats are null but that should never happen');
        answer = threats;
        break;
      case QuestionType.Unprotected:
        answer = unprotectedSquares;
        break;
      default:
        throw new Error('Unknown question type: ' + questionType);
    }

    const answers = [0, 1, 2, 3, 4, 5];
    if (!answers.includes(answer))
      answers.push(answer);
    while (answers.length < 9) {
      const randomAnswer = (Math.random()*5 + 6)|0;
      if (!answers.includes(randomAnswer))
        answers.push(randomAnswer);
    }
    answers.sort((a,b) => a - b);


    setCorrectAnswer(answer);
    setAnswers(answers);
  }, [props.position]);

  const renderQuestion = () => {
    switch (questionType) {
      case QuestionType.Checks:
        return <>How many <b>checking</b> moves can {turnStr} make?</>;
      case QuestionType.Captures:
        return <>How many <b>capturing</b> moves can {turnStr} make?</>;
      case QuestionType.Threats:
        return <>How many {turnStr} pieces are <b>threatened</b>?</>;
      case QuestionType.Unprotected:
        return <>How many {turnStr} pieces are <b>unprotected</b>?</>;
      default:
        throw new Error('Unknown question type: ' + questionType);
    }
  };

  const handleClick = (answer: number) => {
    if (answer === correctAnswer)
      setResult('Correct');
    else
      setResult('Wrong');
  };

  const renderAnswers = () => {
    return answers.map(answer => 
        <button style={{ width: 30, height: 25, margin: 5 }} onClick={() => handleClick(answer)}>{answer}</button>
      );
  };

  return (
    <div>
      {renderQuestion()}
      <div style={{ height: 8 }} />
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', alignContent: 'space-between' }}>
        {renderAnswers()}
      </div>
      <div style={{ marginTop: 12, height: 40, fontSize: 20, fontWeight: 'bold', color: result === 'Wrong' ? 'red' : 'green' }}>
        {result}
      </div>
    </div>
  );
}

export default ChessFacts;
