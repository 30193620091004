import axios from 'axios';
import jszip from 'jszip';
import Chess from './chessjs';
const chess = (Chess as any)() as ChessInstance;

class PgnDatabase {
    private pgns: string[] = [];

    downloadPgns = async () => {
        const response = await axios.get('pgns.zip', { responseType: 'arraybuffer' });
        const zip = await jszip.loadAsync(response.data);
        for (const filePath in zip.files) {
            const data = await zip.file(filePath)?.async('string');
            if (!data)
                throw new Error('Could not find file: ' + filePath);
            
            for (const pgn of data?.split(/(?=\[Event)/)) {
                this.pgns.push(pgn);
            }
        }
    };

    getRandomFen = () => {
        let moveNumberMin = 30;
        
        PgnDatabase.shuffleArray(this.pgns);
        for (const pgn of this.pgns) {
            if (!chess.load_pgn(pgn))
                continue;
            
            const gameLength = chess.history().length;
            if (gameLength < 40)
                continue;
            
            let moveNumber = (Math.random()*(gameLength - moveNumberMin) + moveNumberMin)|0;
            for (let i = gameLength; i > moveNumber; i--)
                chess.undo();
            
            return chess.fen();
        }

        throw new Error('Could not get random FEN');
    };

    private static shuffleArray(array: unknown[]) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }
}

export default new PgnDatabase();