import React, { useState, useEffect } from 'react';
import './App.css';
import Chessboard from "chessboardjsx";
import ChessFacts from './ChessFacts';
import pgnDatabase from './pgn-database';

function App() {
  const [fen, setFen] = useState("");
  const [loaded, setLoaded] = useState(false);
  const nextFen = () => {
    const fen = pgnDatabase.getRandomFen();
    setFen(fen);
    console.log('FEN:', fen);
  };

  useEffect(() => {
    (async () => {
      await pgnDatabase.downloadPgns();
      nextFen();
      setLoaded(true);
    })();
  }, []);

  const renderWhoToMove = () => {
    if (/ w /.test(fen)) {
      return <div style={{ width: '60%', height: 30, backgroundColor: 'white', borderRadius: 5, color: '#999', fontSize: 'smaller', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center' }}>white</div>;
    } else {
      return <div style={{ width: '60%', height: 30, backgroundColor: 'black', borderRadius: 5, color: '#666', fontSize: 'smaller', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center' }}>black</div>;
    }
  };

  return (
    <div className="App">
      {!loaded ?
        <>Loading...</>
      :
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'flex-start', marginTop: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Chessboard position={fen} draggable={false} width={400} orientation={/ w /.test(fen) ? 'white' : 'black'} />
            <div style={{ height: 10 }} />
            {renderWhoToMove()}
          </div>
          <div style={{ width: '10px' }} />
          <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', justifyContent: 'flex-start', alignItems: 'stretch', height: 400, backgroundColor: '#fcfcfc', borderRadius: 6, boxShadow: '0 0 6px 0px black' }}>
            {/* <div>Errors: X X X</div>
            <div>Time left: 00:00</div> */}
            <div style={{ flexGrow: 1 }}>
            </div>
            <div>
              <ChessFacts position={fen} /><br />
              <button onClick={nextFen}>
                Next
              </button>
            </div>
            <div style={{ height: '10px' }} />
          </div>
        </div>
      }
    </div>
  );
}

export default App;
